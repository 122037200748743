@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root > div {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: auto;
  border-radius: 6px;
  background-color: #18191C;
}

.card > .top {
  margin-left: 20px;
  position: relative;
  border-radius: 6px;
  width: 100%;
  height: auto;
}

.top > .shade {
  margin-left: -20px;
  position: absolute;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  height: 50%;
  background-color: #9F815D;
}

.top > .avatar {
  position: relative;
}

.avatar > .pic {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 5px solid #18191C;
  margin-top: calc(85px/4);
  background-color: #9F815D;
}

.avatar > .status {
  width: 16px;
  height: 16px;
  background-color: #3BA45D;
  border-radius: 50%;
  border: 5px solid #18191C;
  position: absolute;
  top: 85px;
  left: 65px;
  z-index: 10;
}

.username {
  margin-left: 20px;
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

.username > .tag {
  color: #b9bbbe;
  font-size: 20px;
  font-weight: bold;
}

.divider {
  padding-left: -20px;
  margin-top: 15px;
  border-bottom: 1px solid hsla(0,0%,100%,0.06);
}

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  margin-left: 20px;
  font-size: 0.85em;
  color: #b9bbbe;
  text-transform: uppercase;
}

.mgtop {
  margin-top: 15px;
}

.mgleft {
  margin-left: 20px;
}

.activity {
  display: flex;
  flex-direction: column;
}

.game {
  display: flex;
  margin-top: 15px;
  margin-left: 20px;
}

.gamelogo {
  width: 50px;
  height: 50px;
}

.gameinfo {
  margin-left: 10px;
  font-weight: 500;
  color: #b9bbbe;
  display: flex;
  flex-direction: column;
}

.gameinfo > .gametitle {
  font-weight: 600;
  color: #dcddde;
}

p {
  margin: 0;
}

.more {
  background-color: transparent;
  text-transform: uppercase;
  color: #dcddde;
  border: 2px solid #dcddde;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  border-radius: 5px;
  margin: 15px;
  padding: 10px;
  transition: all ease-in-out 0.25s;
}

.more:hover {
  transition: all ease-in-out 0.25s;
  color: #282c34;
  border: 2px solid #dcddde;
  background-color: #dcddde;
  cursor: pointer;
}

.more:active {
  transition: all ease-in-out 0.25s;
  color: #282c34;
  border: 2px solid #dcddde;
  background-color: #c6c6c7;
  cursor: pointer;
}

.roles {
  display: flex;
  flex-wrap: wrap;
}


.roles > .role {
  margin-top: 10px;
  margin-right: 6px;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.85em;
  font-weight: 700;
  align-items: center;
  padding: 5px 8px 5px 8px;
  border-radius: 20px;
}

.role > .circle {
  border-radius: 50%;
  height: 0.85em;
  width: 0.85em;
  margin-right: 5px;
}

.blue > * {
  color: #00A6ED;
}

.roleblue {
  border: 1px solid #00A6ED;
}

.rolewhite {
  border: 1px solid #dcddde;
  color: #dcddde;
  padding: 4px 9px 4px 9px !important;
  border-radius: 100% !important;
}

.circleblue {
  background-color: #00A6ED;
}

.red > * {
  color: #F6511D;
}

.rolered {
  border: 1px solid #F6511D;
}

.circlered {
  background-color: #F6511D;
}

.green > * {
  color: #7FB800;
}

.rolegreen {
  border: 1px solid #7FB800;
}

.circlegreen {
  background-color: #7FB800;
}

.addnote {
  color: #c6c6c7;
  margin-top: 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
  font-weight: 400;
}

.message {
  text-align: left;
  color: #72767d;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  border-radius: 5px;
  margin: 15px;
  padding: 10px;
  transition: all ease-in-out 0.25s;
  background-color: #292b2f;
  padding-top: 15px;
  padding-bottom: 15px;
}

.message:hover {
  box-shadow:inset 0px 0px 0px 1px #72767d;
  transition: all ease-in-out 0.25s;
  cursor: text;
}

.extra {
  font-family: 'Roboto', sans-serif;
  font-size: 0.65em;
  color: #72767d;
  text-align: center;
  padding-bottom: 15px;
}

